@use "src/styles/settings" as *;
.PostArea {
  position: sticky;
  bottom: 0;
  padding: 1rem;
  background-color: colors(white);
  box-shadow: 0 -3px 8px 0 rgb(66 91 118 / 21%);
}
.InputLabel {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  color: colors(gray-300);
}
.TextArea {
  width: 100%;
  padding: .5rem 4.75rem .5rem 3.5rem;
  resize: none;
  border: none;
  border-bottom: .0625rem solid colors(gray-200);
  &:hover {
    border-bottom: .0625rem solid colors(gray-700);
  }
  &:focus {
    border-bottom: .0625rem solid colors(gray-900);
    outline: none;
  }
}
.SubmitArea {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
}
.SubmitButton {
  padding: .25rem .5rem;
  font-size: .75rem;
  color: colors(white);
  background-color: colors(gray-800);
  border-radius: .25rem;
  transition: background-color .15s ease-out 0s;
  &:hover {
    background-color: colors(gray-700);
  }
  &:active {
    background-color: colors(gray-600);
  }
}
