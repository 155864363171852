@use "src/styles/settings" as *;
.Forms {
  display: flex;
  flex-direction: column;
  border-top: 1px solid colors(gray-300);
}
.FormRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid colors(gray-300);
}
.FormLabelCol {
  display: flex;
  width: 12rem;
  padding: 1rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
}
.FormSettingCol {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: .5rem 1rem;
}
.InvalidFeedback {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.RequiredLabel {
  margin-left: auto;
}
.FileLink {
  color: colors(link-text);
  vertical-align: middle;
  &:hover {
    color: colors(link-hover);
  }
}
.HideInputFile {
  display: none;
}
.ActionButton {
  margin-left: 1rem;
}
