@import "src/styles/settings";
.EstimateTitle {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  @include mq() {
    padding: 1rem 1rem 0;
  }
}
.Container {
  display: flex;
  flex-direction: row;
  @include mq() {
    flex-direction: column;
  }
}
.EstimateAddForm {
  width: 20rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  @include mq() {
    display: none;
  }
}
.EstimateList {
  flex: 1;
  margin-bottom: 3rem;
  @include mq() {
    margin-bottom: 0;
  }
}
