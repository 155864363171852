@use "src/styles/settings" as *;
.Block {
  display: flex;
}
.BlockCaptionWrapper {
  width: 12.5rem;
  border-right: 1px solid colors(gray-300);
}
.BlockCaption {
  display: flex;
  align-items: center;
  padding-top: .5rem;
}
.BlockIconWrapper {
  padding: .5rem;
  background-color: colors(gray-200);
  border-radius: 99rem;
}
.BlockIcon {
  width: 1.25rem;
  height: 1.25rem;
}
.BlockCaptionLabel {
  margin-left: .5rem;
  font-size: 1rem;
  font-weight: 700;
}
.BlockForms {
  flex: 1;
  padding: 0 1rem;
  .FormRow:last-child {
    border-bottom: 1px solid colors(gray-200);
  }
}
.FormRow {
  display: flex;
  border-top: 1px solid colors(gray-200);
}
.FormLabel {
  display: flex;
  align-items: center;
  width: 16rem;
  padding: 1rem;
  background-color: colors(gray-100);
}
.FormLabelText {
  font-weight: 700;
  color: colors(gray-700);
}
.FormContent {
  flex: 1;
  padding: 1rem;
}
.Description {
  margin-top: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.InputWrapper {
  max-width: 40rem;
}
.InputRadioGroup {
  display: flex;
  gap: 1rem;
}
.InputFileGroup {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}
.NameKanaGroup {
  max-width: 30rem;
}
.NameInputWrapper {
  margin-bottom: .5rem;
}
.NameInputLabel {
  margin-top: .5rem;
  margin-bottom: .25rem;
}
.DateTimeGroup {
  display: flex;
  gap: .5rem;
}
.ErrorMessage {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.InputFile {
  margin-top: 1rem;
}
.FileLink {
  color: colors(link-text);
}
.RequiredLabel {
  margin-left: auto;
}
// 物件選択
.PropertyTypeLabel {
  display: inline-block;
  padding: .25rem .5rem;
  font-size: .75rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-100);
  border-radius: 99rem;
}
.SelectedPropertyLabel {
  display: flex;
  gap: .5rem;
  align-items: center;
  margin-bottom: 1rem;
}
