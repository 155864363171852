@import "src/styles/settings";
.NavigationBlock {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 2rem;
  border: .125rem solid colors(border);
  border-radius: .25rem;
}
.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.Icon {
  width: 4rem;
  height: 3rem;
  color: colors(gray-400);
}
.NavigationContent {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.Title {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 700;
  .OptionalLabel {
    padding: .125rem .375rem;
    margin-left: .5rem;
    font-size: .75rem;
    color: colors(gray-700);
    background-color: colors(gray-200);
    border-radius: .25rem;
  }
}
.LinkButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.DetailLinkBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  .DetailLinkButton {
    display: block;
    width: 10rem;
    padding: .5rem 1rem;
    font-size: 1rem;
    text-align: center;
  }
}
