@use "src/styles/settings" as *;
.Header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 1rem 1.875rem;
  background-color: colors(white);
  box-shadow: 0 .125rem .5rem rgb(0 0 0 / 10%);
  @include mq() {
    flex-direction: column;
    padding: 1rem;
  }
}
.HeaderElements {
  display: flex;
  align-items: center;
  > div + div {
    margin-left: 1rem;
    @include mq() {
      margin-left: .5rem;
    }
  }
  .AlignCenter {
    display: flex;
    align-items: center;
  }
  .DealAddress {
    display: flex;
    @include mq("xl") {
      display: none;
    }
  }
  .Icon {
    width: 1rem;
    height: 1rem;
    margin-right: .25rem;
  }
}
.RoomName {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: .04em;
  @include mq() {
    font-size: 1rem;
  }
}
.Container {
  padding: 1.25rem 1.875rem;
  &.Transparent {
    background-color: transparent;
  }
  &.White {
    background-color: colors(white);
  }
  @include mq() {
    padding: 0;
  }
}
