// color
$theme-color: #8764a7;
$theme-sub-color: rgb(135 100 167 / 10%);

$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

$container-sm: 720px;
$container-md: 960px;
$container-lg: 1140px;

$enable-responsive-font-sizes: true !default;

// ここから下新規定義
// colors
$color-black: #212121;
$color-white: #fff;
$color-theme: #8764a7;
$color-theme-bg: rgb(135 100 167 / 10%);
$color-gray-bg: #f4f5f6;
$color-muted: #6e6e6e;
$color-muted-100: #676767;
$color-muted-200: #79767e;
$color-border: #e1e1e1;
$color-input-border: #ced4da;
$color-shadow: rgb(0 0 0 / 10%);

// font-size
$fs-sm: 12;
$fs-md: 14;
$fs-ml: 16;
$fs-lg: 18;
$fs-xl: 20;
$fs-xxl: 24;

// font-weight
$fw-normal: 400;
$fw-demiBold: 500;
$fw-bold: 600;
