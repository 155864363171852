@import "src/styles/settings";
.react-datepicker {
  &__input-container {
    position: relative;
    display: inline-block;
    & > input {
      box-sizing: border-box;
      min-width: 16rem;
      padding: .75rem .75rem .75rem 2.5rem;
      margin: .0625rem;
      border: .0625rem solid colors(border);
      border-radius: .25rem;
      &:focus {
        margin: 0;
        border: .125rem solid colors(semantic);
        outline: none;
        box-shadow: 0 0 0 .25rem rgb(0 128 255 / 10%);
      }
    }
  }
}
.react-datepicker-wrapper {
  width: auto;
}
.react-datepicker-popper {
  z-index: 1050;
}
