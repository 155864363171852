@use "src/styles/settings" as *;
.List {
  width: 100%;
  max-width: 50rem;
  height: 100%;
  margin-bottom: 1rem;
  border-bottom: 0;
  @include mq() {
    border: none;
    border-top: .0625rem solid colors(border);
  }
}
.ListRow {
  height: 100%;
  background-color: colors(white);
  border-bottom: .0625rem solid colors(gray-200);
  &:hover {
    background-color: colors(gray-50);
  }
}
.Header {
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
  > th {
    padding: 1rem .75rem;
    vertical-align: middle;
  }
  > th + th {
    border-left: .0625rem solid colors(border);
  }
  &:hover {
    background-color: colors(gray-100);
  }
}
.DropdownCheck {
  width: .25rem;
  padding: 1rem;
  background-color: colors(gray-50);
  &:hover {
    background-color: colors(gray-100);
  }
}
.ListCol {
  padding: 1rem;
  vertical-align: middle;
}
.SettingTitle {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  @include mq("xl") {
    margin-bottom: .5rem;
    font-size: 1rem;
  }
}
.ActionButton {
  margin-left: auto;
  .ModalButton {
    padding: .5rem 1.5rem;
    margin-bottom: .75rem;
    font-weight: 700;
    border-radius: 3rem;
    &:hover {
      background: colors(gray-100);
    }
    &.DeleteButton {
      border: .125rem solid colors(negative);
    }
    &.AddButton {
      border: .125rem solid colors(gray-400);
    }
  }
}
.SettingList {
  display: flex;
  max-width: 50rem;
}
