@import "src/styles/settings";
.Button {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  background: transparent;
  border: none;
  border-radius: .25rem;
  transition: background-color 150ms ease-out 0s;
  &:hover {
    background-color: colors(gray-300);
  }
}
