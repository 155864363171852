@use "src/styles/settings" as *;
.Container {
  display: flex;
  height: calc(100vh - 106px);
  &.Client {
    height: calc(100vh - 45px);
  }
}
.MainArea {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}
