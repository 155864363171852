@import "src/styles/settings";
.AnswerTableHeaderRow {
  > th {
    padding: .5rem;
    font-weight: 400;
    color: colors(gray-700);
    vertical-align: middle;
    background-color: colors(gray-100);
    border-bottom: 1px solid colors(gray-200);
  }
  > th + th {
    border-left: 1px solid colors(gray-200);
  }
}
.AnswerTableRow {
  border-bottom: 1px solid colors(gray-200);
  > td {
    max-width: 0;
    padding: .5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
}
.Question {
  width: 40%;
  font-size: 1rem;
}
.Option {
  width: 20%;
  font-size: 1rem;
}
.Note {
  width: 40%;
  font-size: .75rem;
}
.AnswerTable {
  width: 100%;
}
