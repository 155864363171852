@use "src/styles/settings" as *;
.LoginPasswordPanel {
  width: 100%;
  padding: .25rem;
  overflow: hidden;
  white-space: nowrap;
  animation: SlideIn .15s;
  @keyframes SlideIn {
    0% {
      width: 0;
      transform: translateX(300px);
    }
    100% {
      width: 100%;
      transform: translateX(0);
    }
  }
}
.BackwardLink {
  color: colors(link-text);
}
.LoginTitle {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
.ButtonArea {
  text-align: center;
}
.PasswordPanel {
  width: 60%;
  margin: 1.5rem auto;
  @include mq() {
    width: 80%;
  }
}
.Label {
  margin-bottom: .25rem;
}
.ShowPasswordBlock {
  margin: .5rem 0;
}
.SubmitButton {
  display: inline-block;
  width: 14rem;
  padding: .75rem;
  font-size: 1rem;
  color: colors(white);
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 2.08%, rgb(255 255 255 / 20%) 100%), colors(gray-900);
  border-radius: 3rem;
  transition: opacity 150ms ease-out 0s;
  &:hover {
    opacity: .8;
  }
  &:disabled {
    opacity: .5;
  }
}
