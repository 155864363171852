@import "src/styles/settings";
.InvalidFeedback {
  font-size: .75rem;
  color: colors(negative);
}
.TotalAmountView {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  font-size: 1rem;
  font-weight: 700;
  background-color: colors(gray-100);
  border-radius: .5rem;
}
.Description {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
