@import "src/styles/settings";
.DealHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 1rem 1.875rem;
  background-color: colors(white);
  box-shadow: 0 .125rem .5rem rgb(0 0 0 / 10%);
  @include mq() {
    flex-direction: column;
    padding: 1rem;
  }
}
.HeaderLeftContent {
  margin-right: 1rem;
  @include mq() {
    padding-right: .5rem;
  }
}
.HeaderElements {
  display: flex;
  gap: 1rem;
  @include mq() {
    flex-direction: column;
    gap: .5rem;
  }
  .AlignCenter {
    display: flex;
    align-items: center;
    @include mq() {
      align-items: initial;
    }
  }
  .DealAddress {
    @include mq() {
      margin-left: .25rem;
      color: colors(gray-700);
    }
  }
  .Icon {
    min-width: 1rem;
    min-height: 1rem;
    margin-right: .25rem;
  }
}
.RoomName {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: .04em;
  @include mq() {
    margin-left: .25rem;
  }
}
.HeaderRightContent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
  @include mq() {
    margin-top: .5rem;
  }
}
