@use "src/styles/settings" as *;
.HeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .75rem 1rem;
  border-bottom: .0625rem solid colors(border);
  .CloseLink {
    display: flex;
    margin-right: .75rem;
    .CloseIcon {
      width: 1.25rem;
      height: 1.25rem;
      color: colors(gray-900);
    }
  }
}
.TimelineHeader {
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 700;
  line-height: 130%;
  .PartnerName {
    font-size: .75rem;
    font-weight: 400;
  }
}
.LinkWrapper {
  padding-left: .5rem;
  margin-left: auto;
}
.DealLink {
  color: colors(semantic);
  &:hover {
    color: colors(semantic-linkhover);
  }
}
