@import "../../../../styles/settings";
.FormContainer {
  margin: 1rem 0 2rem;
}
.FormGroup {
  margin-bottom: 1rem;
}
.FormLabel {
  padding: .5rem;
  margin-bottom: .25rem;
  background-color: colors(gray-100);
}
.Description {
  margin: .5rem 0;
  font-size: .75rem;
  color: colors(gray-700);
}
.InvalidFeedback {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.FormWrapper {
  display: flex;
  align-items: center;
  .ScaleForm {
    width: 5rem;
  }
}
.ScaleUnitLabel {
  margin-left: .5rem;
}
