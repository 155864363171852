@import "src/styles/settings";
.MailCreateRow {
  display: flex;
  align-items: center;
  padding-bottom: .25rem;
  .EmailForm {
    flex: 2;
  }
  .DelLink {
    width: 4rem;
    text-align: center;
    a.MailDeleteLink {
      font-weight: 700;
      color: colors(red);
    }
  }
}
