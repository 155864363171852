@use "src/styles/settings" as *;
.BackLink {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
  }
}
.Description {
  margin-block: 1rem;
}
.RoomList {
  min-height: 400px;
  max-height: 400px;
  margin-top: 1rem;
  overflow-y: auto;
  border-top: 1px solid colors(gray-300);
}
.Empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  color: colors(gray-400);
}
.ButtonArea {
  display: flex;
  margin-top: 1rem;
}
.Buttons {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
