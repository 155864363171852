html {
  // font-size: 62.5%; //1rem
  background-color: #f5f5f5;
  @include mq {
    height: 100%;
    overflow: auto;
  }
}
body {
  position: relative;
  margin: 0;
  font-family: Roboto, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-feature-settings: "palt";
  line-height: 1.3;
  color: $color-black;
  letter-spacing: .04em;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  @include font-size($fs-md);
}

// numberのspinner隠し
input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input[type="number"] {
  appearance: textfield;
}

// bootstrapの打ち消し
label {
  margin-bottom: 0;
}
