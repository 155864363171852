@use "src/styles/settings" as *;
.DealRow {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background-color: colors(white);
  border-bottom: .0625rem solid colors(gray-200);
}
.DealLink {
  font-size: 1rem;
  text-decoration: underline;
}
.DealTypeBlock {
  margin-bottom: 1rem;
}
.DealVendorBlock {
  flex: 2;
}
.DealStatusBlock {
  flex: 1;
}
.DealEstimationBlock,.DealBillingBlock {
  width: 10rem;
  text-align: right;
}
.ContextLabel {
  margin-bottom: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.ContentText {
  font-size: 1rem;
  &.Empty {
    color: colors(gray-400);
  }
}
.ContentLink {
  font-size: 1rem;
  text-decoration: underline;
}
.SubContentText {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-600);
}
.ProfitBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 8rem;
  padding: 0 .5rem;
  margin-left: 2rem;
  border-left: 1px dashed colors(gray-200);
}
.ProfitRateBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 5rem;
  padding: 0 .5rem;
}
.ProfitText {
  font-size: 1rem;
  .Unit {
    margin-left: .25rem;
    font-size: .75rem;
    color: colors(gray-700);
  }
  .ProfitPositive {
    color: colors(semantic-positive);
  }
  .ProfitNegative {
    color: colors(negative);
  }
  .ProfitZero {
    color: colors(gray-400);
  }
}
.SkLinkBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 8rem;
  padding: 0 .5rem;
  margin-left: 2rem;
  border-left: 1px dashed colors(gray-200);
}
.SkLinkText {
  margin-bottom: .5rem;
  color: colors(gray-400);
  text-align: right;
}
.SkLink {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
    text-decoration: underline;
  }
}
.TotalRow {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: colors(white);
}
.TotalBlock {
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
}
.NotTarget {
  opacity: .4;
}
