@import "src/styles/settings";
.Container {
  width: 100%;
  max-width: 70rem;
  padding: 1.5rem;
  margin: 0 auto;
}
.Header {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: colors(black);
}
.TableContainer {
  position: relative;
  width: 100%;
  max-width: 80rem;
  height: 100%;
  margin-bottom: .5rem;
  overflow: auto;
}
.CompanyTable {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  background: colors(white);
  thead {
    color: colors(gray-700);
    background: colors(gray-100);
    border-bottom: .25rem solid colors(gray-200);
  }
  th {
    padding: .75rem 1.5rem;
    font-weight: 400;
    vertical-align: middle;
    border-top: .0625rem solid colors(gray-200);
    border-bottom: .0625rem solid colors(gray-200);
  }
}
.IdCell {
  width: 12.5rem;
}
.ProxyLoginLink {
  color: colors(link-text);
}
.TableTopArea {
  margin-bottom: 1rem;
}
.SearchArea {
  position: relative;
  width: 20rem;
  @include mq() {
    width: 100%;
  }
}
.SearchIcon {
  position: absolute;
  top: .8125rem;
  left: .875rem;
  z-index: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: colors(gray-400);
}
.SearchInput {
  padding-left: 2.5rem;
}
.PaginationArea {
  margin-top: 1rem;
}
