@use "src/styles/settings" as *;
.TopArea {
  display: flex;
  gap: 24px;
}
.UploadPanelWrapper {
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
}
.TablePanelWrapper {
  width: 100%;
  max-width: 800px;
  margin-bottom: 1rem;
}
.SubTitle {
  margin-bottom: 1rem;
  font-weight: 700;
}
.Link {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
  }
}
