@use "src/styles/settings" as *;
.FormGroup {
  margin-bottom: 2rem;
}
.GroupLabel {
  padding: 1rem .75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
  background-color: colors(gray-200);
  border-left: .25rem solid colors(gray-800);
}
.FormList {
  display: flex;
  flex-direction: column;
  border-bottom: .0625rem solid colors(gray-200);
}
.FormBlock {
  display: flex;
  border-top: .0625rem solid colors(gray-200);
}
.FormBlockDescription {
  margin-top: 1rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.FormLabel {
  display: flex;
  align-items: center;
  width: 14rem;
  padding: 1rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-100);
}
.FormContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: .75rem;
  background-color: colors(white);
}
.NameContent {
  display: flex;
  > div {
    flex: 1;
  }
  > div + div {
    margin-left: .5rem;
  }
}
.Description {
  margin-top: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.RequireLabel {
  padding: .0625rem .375rem;
  margin-left: auto;
  font-size: .75rem;
  font-weight: 700;
  color: colors(negative);
  background-color: colors(negative-100);
  border: .0625rem solid colors(negative);
  border-radius: .125rem;
}
.OptionalLabel {
  padding: .0625rem .375rem;
  margin-left: auto;
  font-size: .75rem;
  font-weight: 700;
  color: colors(gray-600);
  background-color: colors(gray-200);
  border: .0625rem solid transparent;
  border-radius: .125rem;
}
.ErrorMessage {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.RadioGroup {
  > * + * {
    margin-left: 1rem;
  }
}
.TermLink {
  font-weight: 700;
  color: colors(gray-900);
  text-decoration: underline;
}
.Checkboxes {
  > * + * {
    margin-top: 1rem;
  }
}
