@import "src/styles/settings";
.Container {
  width: 100%;
  max-width: 70rem;
  padding: 1.5rem;
  margin: 0 auto;
}
.Header {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: colors(black);
  //.AllCount {
  //  margin-left: .5rem;
  //  font-size: .75rem;
  //  font-weight: 400;
  //  color: colors(muted);
  //}
}
//.ActionArea {
//  display: flex;
//  margin-bottom: .5rem;
//  .SearchArea {
//    flex: 1;
//    max-width: 20rem;
//  }
//  .AddButtonArea {
//    margin-left: auto;
//  }
//}
.TableContainer {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 280px);
  margin-bottom: .5rem;
  overflow: auto;
  //&.NoPagination {
  //  max-height: calc(100vh - 240px);
  //}
}
.CompanyTable {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  background: colors(white);
  border-radius: .5rem;
  box-shadow: 0 .125rem .5rem rgb(0 0 0 / 16%);
  thead {
    position: sticky;
    top: 0;
    z-index: 0;
    background: colors(white);
    border-bottom: .25rem solid colors(border);
  }
}
.CompanyRow {
  transition: background-color 150ms ease-out 0s;
  &:hover {
    background-color: colors(gray-50);
  }
  th {
    font-weight: 400;
    color: colors(gray-900);
    border-bottom: .125rem solid colors(border);
  }
  td {
    border-bottom: .0625rem solid colors(border);
  }
  th, td {
    padding: .75rem 1.5rem;
  }
}
.IdCell {
  width: 8rem;
}
.ProxyLoginCell {
  width: 10rem;
}
.MenuCell {
  width: 5rem;
}
.ProxyLoginLink {
  color: colors(link-text);
}
.TableTop {
  display: flex;
  margin-bottom: 1rem;
}
.AddButton {
  margin-left: auto;
}
