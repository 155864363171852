@use "src/styles/settings" as *;
.List {
  width: 100%;
  max-width: 50rem;
  height: 100%;
  margin-bottom: 1rem;
  border-bottom: 0;
  @include mq() {
    border: none;
    border-top: .0625rem solid colors(border);
  }
}
.ListRow {
  height: 100%;
  background-color: colors(white);
  border-bottom: .0625rem solid colors(gray-200);
  transition: background-color 150ms ease-out 0s;
  &:hover {
    background-color: colors(gray-50);
  }
  .UnitAMount {
    text-align: right;
  }
}
.TitleList {
  display: flex;
  @include mq("xl") {
    display: block;
  }
}
.vendorCompanyName {
  margin-right: .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  @include mq("xl") {
    margin-bottom: .5rem;
    font-size: .75rem;
  }
}
.SettingTitle {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  @include mq("xl") {
    margin-bottom: .5rem;
    font-size: 1rem;
  }
}
.ListCol {
  padding: 1rem .75rem;
}
.Header {
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
  > th {
    padding: 1rem .75rem;
    vertical-align: middle;
  }
  > th + th {
    text-align: right;
  }
}
