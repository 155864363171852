@import "src/styles/settings";
.Breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  font-size: .75rem;
  list-style: none;
}
.BreadcrumbItem {
  display: flex;
  a {
    color: colors(link-text);
  }
  & + .BreadcrumbItem {
    padding-left: .5rem;
    &::before {
      display: inline-block;
      padding-right: .5rem;
      color: colors(gray-700);
      content: "/";
    }
  }
}
