@use "src/styles/settings" as *;
.Forms {
  display: flex;
  flex-direction: column;
  margin: .5rem;
}
.FormRow {
  border-top: 1px solid colors(gray-300);
  border-bottom: 1px solid colors(gray-300);
}
.SurveyTitle {
  margin-top: .25rem;
  color: colors(gray-900);
}
.SurveyDescription {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.RadioGroup {
  display: flex;
  gap: 1rem;
  padding-block: .5rem;
}
.InvalidFeedback {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.Note {
  margin: .25rem;
}
