@use "src/styles/settings" as *;
.Title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.Container {
  padding: 2.5rem 2%;
  margin-bottom: 10rem;
}
