@use "src/styles/settings" as *;
.Row {
  display: flex;
  > .Col + .Col {
    margin-left: .5rem;
  }
}
.Col {
  flex: 1;
  width: 30%;
}
.SubmitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: .75rem;
  font-size: 1rem;
  color: colors(white);
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 2.08%, rgb(255 255 255 / 20%) 100%), colors(gray-900);
  border-radius: 3rem;
  &:disabled {
    opacity: .5;
  }
}
.CancelButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: .75rem;
  font-size: 1rem;
  font-weight: 700;
  background: colors(gray-200);
  border-radius: 3rem;
  &:disabled {
    opacity: .5;
  }
}
