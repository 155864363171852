@import "src/styles/settings";
.CancelButtonWrapper {
  margin-left: auto;
  .CancelButton {
    color: colors(negative);
    &:hover {
      color: colors(negative);
    }
  }
}
