@use "src/styles/settings" as *;
.VendorRow {
  border-bottom: .0625rem solid colors(border);
  transition: background-color 150ms ease-out 0s;
  &.Active {
    background-color: colors(semantic-link100);
  }
  &:hover {
    background-color: colors(gray-100);
  }
}
.VendorLink {
  display: flex;
  padding: .75rem 1rem;
  color: colors(gray-900);
  &:hover {
    color: colors(gray-900);
    text-decoration: none;
  }
}
.VendorNameRow {
  margin-bottom: .25rem;
}
.VendorName {
  font-size: 1rem;
  font-weight: 700;
}
.VendorNameExtra {
  margin-left: .5rem;
}
.VendorAddress {
  font-size: .75rem;
  color: colors(gray-700);
}
.IconWrapper {
  width: 2.5rem;
  margin-right: 1rem;
}
.Icon {
  width: 2.5rem;
  height: 2.5rem;
}
.ArrowIconWrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.ArrowIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: colors(gray-400);
}
