@use "src/styles/settings" as *;
.HideFilePostInput {
  position: absolute;
  z-index: -1;
  display: none;
  visibility: hidden;
}
.SettingContents {
  display: flex;
  gap: 16px;
  align-items: center;
}
.SettingLabel {
  padding: 4px 12px;
  font-weight: 700;
  border-radius: 4px;
  &.NoSetting {
    color: colors(gray-400);
    background-color: colors(gray-100);
  }
  &.Uploaded {
    color: colors(semantic-positive);
    background-color: colors(semantic-positive-100);
  }
}
.Link {
  color: colors(link-text);
}
