@import "src/styles/settings";
.DropdownToggle {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: .25rem;
  transition: background-color 150ms ease-out 0s;
  &:active {
    outline: none;
  }
  &.Medium {
    padding: .375rem .75rem;
    font-size: .875rem;
  }
  &.Small {
    padding: .25rem .5rem;
    font-size: .75rem;
  }
  &.Primary {
    color: colors(white);
    background-color: colors(primary);
    &:disabled {
      background-color: colors(primary);
      opacity: .5;
    }
  }
  &.Secondary {
    color: colors(primary);
    background-color: colors(white);
    border: .0625rem solid colors(primary);
    &:disabled {
      background-color: colors(white);
      opacity: .5;
    }
  }
  &.Normal {
    color: colors(gray-900);
    background-color: colors(gray-200);
    &:hover {
      color: colors(gray-900);
      background-color: colors(gray-300);
    }
    &:disabled {
      background-color: colors(gray-200);
      opacity: .5;
    }
  }
  &.Link {
    border: .0625rem solid colors(border);
    &:disabled {
      opacity: .5;
    }
  }
  &.NoBorder {
    border: none;
  }
}
.DropdownPopper {
  z-index: 10;
}
.Hide {
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
}
.DropdownTitle {
  margin-right: .5rem;
}
.NoBorder {
  .DropdownTitle {
    margin-right: 0;
  }
}
.DropdownIcon {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
}
