@import "src/styles/settings";
.ReportImagesArea {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: colors(white);
  @include mq() {
    display: none;
  }
}
.ReportImageList {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  @include mq() {
    flex-flow: column nowrap;
  }
}
