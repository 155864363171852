@use "src/styles/settings" as *;
.Container {
  display: flex;
  overflow-x: hidden;
}
.UserListPanel {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  &.Hide {
    width: 0;
    height: 0;
    visibility: hidden;
    animation: SlideOut .15s;
    @keyframes SlideOut {
      0% {
        width: 100%;
        visibility: visible;
        transform: translateX(0);
      }
      100% {
        width: 0;
        height: 0;
        visibility: hidden;
        transform: translateX(-300px);
      }
    }
  }
}
.UserLink {
  display: flex;
  align-items: center;
  padding: .75rem 1rem;
  border-bottom: .0625rem solid colors(border);
  transition: background-color 150ms ease-out 0s;
  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: colors(semantic-link100);
  }
}
.UserName {
  font-size: 1rem;
}
.Email {
  margin-top: .25rem;
  font-size: .875rem;
  color: colors(gray-700);
}
.IconWrapper {
  margin-left: auto;
}
.ArrowIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: colors(gray-400);
}
.LockIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
  color: colors(gray-400);
}
