@import "src/styles/settings";
.ReportImageRow {
  display: flex;
  padding: 4px 0;
}
.DragHandleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .125rem;
  cursor: pointer;
  background-color: colors(gray-100);
}
.ImageBlock {
  position: relative;
  width: 55%;
}
.Image {
  width: 100%;
}
.RemarksBlock {
  flex: 1;
  padding: 0 .5rem;
}
.No {
  margin-bottom: .5rem;
  font-size: .75rem;
  color: colors(muted-700);
}
.RemarkRow {
  position: relative;
  margin-bottom: .5rem;
}
.Label {
  padding: .25rem .5rem;
  margin-bottom: .25rem;
  font-size: .75rem;
  background-color: colors(gray-100);
}
.Content {
  padding: .5rem;
}
.YetInput {
  color: colors(gray-400);
}
.NumberPlate {
  position: absolute;
  top: 0;
  left: 0;
  padding: .25rem .5rem;
  font-size: .625rem;
  color: colors(white);
  background-color: rgba(colors(black), .5);
}
.NotSharedPlate {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: .25rem .5rem;
  font-size: .625rem;
  color: colors(white);
  background-color: rgba(colors(negative), .5);
}
.NotOutPutPdf {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: .25rem .5rem;
  font-size: .625rem;
  color: colors(white);
  background-color: rgba(colors(negative), .5);
}
.NotOutPutPdfNote {
  margin-top: 4px;
  font-size: .75rem;
  color: colors(negative);
}
.DropdownMenuIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .75rem;
}
.DeleteLink {
  display: flex;
  align-items: center;
  color: colors(negative);
}
.ButtonWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  padding: .5rem;
  .MenuDropdown {
    padding: 0;
  }
  .MenuButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(255 255 255 / 70%);
    border-radius: 99rem;
  }
  .MenuIcon {
    width: 1rem;
    height: 1rem;
    color: colors(gray-900);
  }
}
.disabled {
  pointer-events: none;
  opacity: .5;
}
.Targeted {
  margin-bottom: .25rem;
  border: 2px solid colors(semantic-positive);
  border-radius: 6px;
}
.ConstructionState {
  width: 100%;
  padding: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
  background-color: colors(semantic-positive-100);
}
.ImageCheckbox {
  position: absolute;
  top: 0;
  right: 0;
}
