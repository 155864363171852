@import "src/styles/settings";
.TableFooter {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.PaginationContainer {
  display: flex;
  justify-content: center;
}
.PC {
  @include mq() {
    display: none;
  }
}
.SP {
  display: none;
  @include mq() {
    display: flex;
  }
}
