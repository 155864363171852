@import "src/styles/settings";
.EstimateTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.25rem 0;
  @include mq() {
    flex-direction: column;
    align-items: flex-start;
  }
}
.Amounts {
  @include mq() {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
}
.AddButton {
  display: none;
  @include mq() {
    display: flex;
    width: 100%;
  }
}
.TotalAmount {
  font-size: 1rem;
  font-weight: 700;
}
.TotalAmountWithoutTax {
  margin-left: .5rem;
}
.Annotate {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-600);
}
