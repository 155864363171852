@use "src/styles/settings" as *;
.SettingTitle {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
}
.SettingSubTitle {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
}
.TitleDescription {
  margin-bottom: 1.25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.NoBackgroundLabel {
  margin-bottom: .25rem;
}
.SettingBlock {
  margin-bottom: 2rem;
}
.BreadcrumbsBlock {
  margin-bottom: .5rem;
}
.FormDescription {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.ShortSizeBlock {
  max-width: 15rem;
}
.MiddleSizeBlock {
  max-width: 25rem;
}
