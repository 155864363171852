@import "src/styles/settings";
.NavigationBlock {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 2rem;
  border: .125rem solid colors(border);
  border-radius: .25rem;
}
.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.Icon {
  width: 4rem;
  height: 3rem;
  color: colors(gray-400);
}
.SpImageCount {
  display: none;
  margin-top: -.5rem;
  color: colors(gray-700);
  @include mq("xl") {
    display: block;
  }
}
.ThumbnailImageList {
  display: flex;
  @include mq("xl") {
    display: none;
  }
}
.ThumbnailImage {
  width: 5.75rem;
  height: 5rem;
  margin-right: .5rem;
  border-radius: .5rem;
}
.NotYet {
  margin-top: -.5rem;
  color: colors(gray-700);
}
.AdditionalImageCountBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 5rem;
  font-size: 1.75rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-100);
  border-radius: .5rem;
}
.NavigationContent {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.Title {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
  .OptionalLabel {
    padding: .125rem .375rem;
    margin-left: .5rem;
    font-size: .75rem;
    color: colors(gray-700);
    background-color: colors(gray-200);
    border-radius: .25rem;
  }
}
.LinkButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.DetailLinkBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.DetailLink {
  display: block;
  width: 10rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  color: colors(gray-900);
  text-align: center;
  background-color: colors(gray-200);
  border-radius: .25rem;
  transition: background-color 150ms ease-out 0s;
  &:hover {
    color: colors(gray-900);
    text-decoration: none;
    background-color: colors(gray-300);
  }
}
