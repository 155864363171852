@use "src/styles/settings" as *;
.ProductLink {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: .5rem;
  color: colors(gray-900);
  border: 1px solid colors(gray-200);
  border-radius: 8px;
  transition: .2s background-color ease-in-out;
  &:focus, &:hover {
    color: colors(gray-900);
    text-decoration: none;
    background-color: colors(gray-50);
  }
  .ProductTitle {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: .1rem;
    .Text {
      font-weight: 700;
    }
    .LinkIcon {
      color: colors(semantic);
    }
  }
  .ProductName {
    margin-top: 2px;
    font-size: .75rem;
    color: colors(gray-900);
  }
}
