@import "src/styles/settings";
.ReportImagesArea {
  display: none;
  margin-top: 1rem;
  background-color: colors(white);
  @include mq() {
    display: block;
  }
}
.ReportImageList {
  display: flex;
  flex-direction: column;
}
.Buttons {
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: .0625rem solid colors(border);
}
.PdfButton {
  width: 50%;
  padding: .5rem 0;
  margin-right: .5rem;
  font-weight: 700;
  text-align: center;
  border: .125rem solid colors(gray-400);
  border-radius: 3rem;
  box-shadow: 0 .0625rem .125rem rgb(94 91 100 / 5%);
  transition: background-color 150ms ease-out 0s;
  &:hover {
    background-color: colors(gray-100);
  }
  &:disabled {
    opacity: .5;
    &:hover {
      background-color: colors(white);
    }
  }
}
