@use "src/styles/settings" as *;
.NavigationBlock {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 2rem;
  border: .125rem solid colors(border);
  border-radius: .25rem;
}
.NavigationContent {
  min-width: 30rem;
}
.StatusHandleButtonRow {
  display: flex;
  margin-top: 2rem;
}
.RightButtons {
  display: flex;
  margin-left: auto;
}
.ForwardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem 1.25rem;
  font-size: 1rem;
  color: colors(white);
  background:
    linear-gradient(
      180deg,
      rgb(255 255 255 / 0%) 2.08%,
      rgb(255 255 255 / 20%) 100%
    ),
    colors(gray-900);
  border-radius: 3rem;
  transition: opacity 150ms ease-out 0s;
  &:hover {
    opacity: .9;
  }
  &:disabled {
    opacity: .5;
  }
}
.NavigationTitle {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
}
.SavedCompanyVendor {
  display: block;
  margin-bottom: 1rem;
}
