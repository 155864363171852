@import "src/styles/settings";
.NavigationBlock {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 2rem;
  border: .125rem solid colors(border);
  border-radius: .25rem;
}
.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.Icon {
  width: 4rem;
  height: 3rem;
  color: colors(gray-400);
}
.NavigationContent {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.Title {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 700;
}
.LinkButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.DetailLinkBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.DetailLink {
  display: block;
  width: 10rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  color: colors(gray-900);
  text-align: center;
  background-color: colors(gray-200);
  border-radius: .25rem;
  transition: background-color 150ms ease-out 0s;
  &:hover {
    color: colors(gray-900);
    text-decoration: none;
    background-color: colors(gray-300);
  }
}
