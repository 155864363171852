@import "src/styles/settings";
.TableFooter {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.PaginationContainer {
  display: flex;
  justify-content: center;
}
