.ErrorContainer {
  padding: 5rem;
}
.ErrorTitle,.ErrorMessage {
  text-align: center;
}
.ErrorTitle {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}
