@use "src/styles/settings" as *;
.DealEditorFooter {
  position: sticky;
  bottom: 0;
  display: flex;
  padding: 1rem 2%;
  background-color: colors(white);
  box-shadow: 0 0 10px rgba(40, 36, 48, 20%);
}
.SaveButton {
  width: 160px;
  margin-left: auto;
}
.Icon {
  color: colors(white);
}
