@import "src/styles/settings";
.ErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10rem;
  padding: 1rem 2rem;
  @include mq() {
    padding: 0;
  }
  .ErrorTitle {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .ErrorMessage {
    font-size: .875rem;
    font-weight: 400;
  }
}
