@import "src/styles/settings";
.BBHeader {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: .875rem;
  background-color: colors(white);
}
.FirstNav {
  position: relative;
  display: flex;
  align-items: center;
  padding: .75rem 1.5rem;
  @include mq() {
    justify-content: center;
    padding: .5rem;
  }
}
.ItandiBBLogo {
  width: 10rem;
  min-width: 10rem;
}
