@import "src/styles/settings";
.Remarks {
  display: flex;
  flex-direction: column;
  padding: .5rem 0;
  font-size: .75rem;
  .RemarkRow {
    display: flex;
    align-items: flex-start;
    margin-bottom: .25rem;
  }
  .NumberRow {
    margin-bottom: .5rem;
  }
}
.Label {
  width: 33%;
  padding: .5rem 0 .5rem .5rem;
  color: colors(gray-700);
}
.Content {
  width: 67%;
  color: colors(gray-900);
  white-space: pre-wrap;
}
.ContentView {
  padding: .5rem .75rem;
}
.YetInput {
  color: colors(gray-400);
}
.EditLink {
  margin-left: auto;
}
.EditLinkButton {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
  }
}
.NotOutPutPdfNote {
  padding-left: .75rem;
  font-size: .6rem;
  color: colors(negative);
}
.ConstructionState {
  width: 100%;
  padding: .25rem .5rem;
  font-size: .75rem;
  color: colors(gray-700);
  background-color: colors(semantic-positive-100);
}
