@import "src/styles/settings";
.DropdownToggle {
  display: flex;
  align-items: center;
  background-color: transparent;
}
.DropdownPopper {
  z-index: 10;
}
.Hide {
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
}
