@import "src/styles/settings";
.EstimateTitle {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  @include mq() {
    padding: 1rem 1rem 0;
  }
}
.Container {
  display: flex;
  flex-direction: row;
  @include mq() {
    flex-direction: column;
  }
}
.EstimateAddForm {
  width: 20rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  background-color: colors(white);
  border: .0625rem solid colors(gray-200);
  @include mq() {
    display: none;
  }
}
.EstimateList {
  flex: 1;
  margin-bottom: 3rem;
  @include mq() {
    margin-bottom: 0;
  }
}
.CompleteButtonArea {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
  margin-top: 1rem;
  @include mq() {
    padding: 1rem;
    margin-top: 0;
  }
}
.CompleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  padding: .75rem;
  font-size: 1rem;
  font-weight: 700;
  color: colors(white);
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 2.08%, rgb(255 255 255 / 20%) 100%), colors(gray-900);
  border-radius: 3rem;
  @include mq() {
    width: 100%;
  }
}
