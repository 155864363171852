@use "src/styles/settings" as *;
.Container {
  width: 50rem;
  margin: 2rem auto 5rem;
}
.Title {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.Indicator {
  margin: 1.5rem 0;
}
.IndicatorWrapper {
  margin: 0 auto;
}
.InnerPageContainer {
  padding: 1.5rem;
  background-color: colors(white);
}
.CompleteTitle {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
.CompleteText {
  margin-bottom: 2rem;
  line-height: 170%;
  text-align: center;
}
.OneButtonArea {
  width: 12.5rem;
  margin: 0 auto;
}
.TwoButtonsArea {
  display: flex;
  width: 23rem;
  margin: 0 auto;
  > div {
    flex: 1;
  }
  > div + div {
    margin-left: 1rem;
  }
}
