@import "src/styles/settings";
.Container {
  padding: 1.25rem 1.875rem;
  &.Transparent {
    background-color: transparent;
  }
  &.White {
    background-color: colors(white);
  }
  @include mq() {
    padding: 0;
  }
}
