@use "src/styles/settings" as *;
.DealEditorBody {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 100vh;
  padding: 1.5rem 2%;
  background-color: colors(white);
}
