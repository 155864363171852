@use "src/styles/settings" as *;
.List {
  width: 100%;
  max-width: 50rem;
  height: 100%;
  margin-bottom: 1rem;
  border-bottom: 0;
  @include mq() {
    border: none;
    border-top: .0625rem solid colors(border);
  }
}
.ListRow {
  height: 100%;
  background-color: colors(white);
  border-bottom: .0625rem solid colors(gray-200);
  &:hover {
    background-color: colors(gray-50);
  }
  .Edit {
    width: 4rem;
    padding: 1rem .75rem;
    .EditLink {
      display: block;
      margin-bottom: .25rem;
      color: colors(link-text);
      text-decoration: underline;
      &:hover {
        color: colors(link-hover);
      }
    }
  }
}
.ButtonArea {
  position: relative;
  .Button {
    position: absolute;
    padding: .75rem 1rem;
    font-size: 1rem;
    color: colors(gray-900);
    border: .125rem solid colors(gray-400);
    border-radius: 3rem;
    &:hover {
      text-decoration: none;
      background-color: colors(gray-100);
    }
    &:active {
      background-color: colors(gray-200);
    }
  }
}
.ListCol {
  padding: 1rem .75rem;
  vertical-align: middle;
}
.Header {
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
  > th {
    padding: 1rem .75rem;
    vertical-align: middle;
  }
  &:hover {
    background-color: colors(gray-100);
  }
}
