@use "src/styles/settings" as *;
.ChatDealListPane {
  flex: 1;
  width: calc(100vh - 50rem);
  padding: 1.875rem;
  overflow: auto;
  @include mq("xxl") {
    width: calc(100vh - 34rem);
  }
  @include mq("xl") {
    width: calc(100vh - 25);
  }
  @include mq() {
    padding: 1rem 0;
  }
}
.TitleWrapper {
  display: flex;
  margin-bottom: 1rem;
}
.Title {
  align-items: center;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: colors(gray-900);
  @include mq() {
    padding: 0 1rem;
  }
}
