@use "src/styles/settings" as *;
.Row {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1rem .5rem;
  border-bottom: 1px solid colors(gray-300);
}
.Top {
  display: flex;
  @include mq() {
    flex-direction: column-reverse;
    gap: 2px;
  }
}
.Text {
  max-width: 60rem;
}
.Datetime {
  margin-left: auto;
  font-size: .75rem;
  color: colors(gray-700);
  @include mq() {
    margin-left: 0;
  }
}
.Bottom {
  display: flex;
  gap: 2px;
  font-size: .75rem;
  color: colors(gray-700);
}
