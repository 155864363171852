@use "src/styles/settings" as *;
.FormRow {
  display: flex;
  gap: .5rem;
  margin-bottom: 1rem;
}
.FormCol {
  flex: 1;
  width: 100%;
}
