@import "src/styles/settings";
.DropdownMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.DropdownMenuLink {
  display: inline-block;
  padding: .5rem 1rem;
  color: colors(gray-900);
  &:hover {
    color: colors(gray-900);
    text-decoration: none;
    background-color: colors(gray-100);
  }
}
