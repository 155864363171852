@use "src/styles/settings" as *;
.SettingLayout {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  @include mq() {
    padding: 0;
  }
}
.SettingMenu {
  flex: 0 0 33%;
  max-width: 16rem;
  min-height: calc(100vh - 9rem);
  @include mq() {
    display: none;
    &.SettingMenuSp {
      display: block;
      flex: 1;
      max-width: none;
      padding: 1.5rem;
    }
  }
  @include mq("xl") {
    max-width: 12rem;
  }
  .SettingHeader {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .SettingSubHeader {
    display: flex;
    align-items: center;
    margin-bottom: .75rem;
    font-weight: 700;
    @include mq() {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
    .HeaderIcon {
      width: 1rem;
      height: 1rem;
      margin-right: .25rem;
      @include mq() {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    &.Disabled {
      opacity: .5;
    }
  }
  .SettingList {
    margin-bottom: 1.5rem;
  }
  .SettingItem {
    margin-bottom: .5rem;
    margin-left: 1rem;
    border-radius: .5rem;
    transition: background-color 150ms ease-out 0s, color 150ms ease-out 0s;
    @include mq() {
      margin-left: 0;
      font-size: 1.125rem;
      font-weight: 700;
      background: linear-gradient(180deg, #fff 0%, #f9f9fb 100%), #fff;
      border: 1px solid colors(gray-300);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 15%);
    }
    &:hover {
      color: inherit;
      background-color: colors(gray-200);
      @include mq() {
        background-color: inherit;
      }
    }
    &.ActiveMenu {
      font-weight: 700;
      color: colors(semantic);
      background-color: colors(semantic-link100);
      transition: background-color 150ms ease-out 0s;
      @include mq() {
        font-size: 1.125rem;
        font-weight: 700;
        color: inherit;
        background-color: colors(white);
      }
    }
    &.Disabled {
      pointer-events: none;
      opacity: .5;
      &:hover {
        background-color: inherit;
      }
    }
  }
  .SettingParentItem {
    margin-bottom: .5rem;
    margin-left: 1rem;
  }
  .MenuLink {
    display: block;
    padding: .75rem 1rem;
    font-size: .875rem;
    color: inherit;
    @include mq() {
      padding: 1rem 1.25rem;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
.SettingContent {
  flex: 1;
  min-height: calc(100vh - 9rem);
  padding: 1rem;
  margin-left: 1rem;
  background-color: colors(white);
  border-radius: .5rem;
  &.SpMenu {
    @include mq() {
      display: none;
    }
  }
  @include mq () {
    min-height: auto;
    margin-left: 0;
    border-radius: 0;
  }
}
.AdminLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  padding: 0 8px;
  margin-left: 8px;
  font-size: .6875rem;
  font-weight: 700;
  color: colors(negative);
  background-color: colors(negative-100);
  border: 1px solid colors(negative);
  border-radius: 2px;
}
