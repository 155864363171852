@use "src/styles/settings" as *;
.ChatContainer {
  position: fixed;
  top: 3rem;
  right: 1rem;
  bottom: 0;
  z-index: z-index(chat-panel);
  display: flex;
  flex-direction: column;
  width: 35rem;
  height: calc(100vh - 3rem);
  background-color: colors(white);
  box-shadow: 0 0 .75rem 0 rgb(66 91 118 / 21%);
  transition: opacity .15s, transform .15s, visibility .15s;
  &.Show {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
  &.Hide {
    visibility: hidden;
    opacity: 0;
    transform: translateX(400px);
  }
  @include mq() {
    display: none;
  }
}
.Header {
  display: flex;
  align-items: center;
  padding: .25rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-100);
  box-shadow: 0 .125rem .5rem rgb(0 0 0 / 10%);
}
.CloseButtonWrapper {
  display: flex;
  align-items: center;
  height: 3rem;
  margin-left: auto;
}
.CloseButton {
  color: colors(white);
  background-color: colors(gray-400);
  border-radius: 99rem;
  transition: background-color .15s;
  > .CloseIcon {
    width: 1.75rem;
    height: 1.75rem;
  }
  &:hover {
    background-color: colors(gray-500);
  }
}
