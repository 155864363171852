$font-size-base: .875rem;
@import "./variables";
@import "./mixin";
@import "~bootstrap/scss/bootstrap";
@import "~destyle.css/destyle";
@import "./base";
@import "~react-datepicker/src/stylesheets/datepicker";
@import "~react-toastify/dist/ReactToastify";
@import "./layout";
@import "./override_react-datepicker";
@import "~@itandi/itandi-bb-ui/index.css";
