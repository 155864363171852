@import "src/styles/settings";
.ImageBlockWrapper {
  height: auto;
  padding: 4px;
  margin: .1rem;
  border-radius: 6px;
}
.ImageBlock {
  width: 12.5rem;
  background-color: colors(white);
}
.DragHandleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: .75rem;
  cursor: pointer;
  background-color: colors(gray-100);
}
.ImageWrapper {
  position: relative;
}
.Image {
  width: 100%;
  height: 7.7rem;
  object-fit: contain;
}
.NumberPlate {
  position: absolute;
  top: 0;
  left: 0;
  padding: .25rem .5rem;
  font-size: .625rem;
  color: colors(white);
  background-color: rgba(colors(black), .5);
}
.NotSharedPlate {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: .25rem .5rem;
  font-size: .625rem;
  color: colors(white);
  background-color: rgba(colors(negative), .5);
}
.NotOutPutPdf {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: .25rem .5rem;
  font-size: .625rem;
  color: colors(white);
  background-color: rgba(colors(negative), .5);
}
.DropdownMenuIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .75rem;
}
.DeleteLink {
  display: flex;
  align-items: center;
  color: colors(negative);
}
.ButtonWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  padding: .5rem;
  .MenuDropdown {
    padding: 0;
  }
  .MenuButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(255 255 255 / 70%);
    border-radius: 99rem;
  }
  .MenuIcon {
    width: 1rem;
    height: 1rem;
    color: colors(gray-900);
  }
}
.disabled {
  pointer-events: none;
  opacity: .5;
}
.Targeted {
  background-color: colors(semantic-positive-500);
  > div { border: 2px solid colors(semantic-positive); }
}
.ImageCheckbox {
  position: absolute;
  top: 0;
  right: 0;
}
