@use "src/styles/settings" as *;
.TableHeader {
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
  > th {
    padding: 1rem .75rem;
    vertical-align: middle;
  }
}
