@use "src/styles/settings" as *;
.SPDealDetail {
  display: none;
  margin: 1rem;
  @include mq() {
    display: block;
  }
  > .SPDealDetailLink + .SPDealDetailLink {
    margin-top: 1rem;
  }
  .SPDealDetailLink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    color: colors(gray-700);
    text-align: center;
    letter-spacing: .1em;
    background-color: colors(white);
    border: .125rem solid colors(gray-400);
    border-radius: 99rem;
    &:hover {
      color: colors(gray-700);
      text-decoration: none;
      background-color: colors(gray-100);
    }
    &:active {
      color: colors(gray-700);
      text-decoration: none;
      background-color: colors(gray-200);
    }
  }
}
.UnreadCountBadge {
  position: relative;
  display: inline-block;
  padding: .125rem .344rem;
  margin-left: .5rem;
  font: bold .75rem/1 sans-serif;
  line-height: .875rem;
  color: colors(white);
  background-color: colors(negative);
  border-radius: 99rem;
}
