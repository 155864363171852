@use "src/styles/settings" as *;
.DeleteLink {
  display: flex;
  align-items: center;
  color: colors(negative);
}
.DropdownMenuIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .75rem;
}
