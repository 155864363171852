@use "src/styles/settings" as *;
.MessageRow {
  display: flex;
  &.Self {
    flex-direction: row-reverse;
    margin-left: auto;
  }
  &.Partner {
    margin-right: auto;
  }
}
.MessageArea {
  flex: 1 1;
  max-width: 80%;
  margin: 0 1rem;
}
.MessageTitle {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: .25rem;
  overflow: hidden;
  font-size: .75rem;
  color: colors(gray-700);
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Message {
  flex: 1;
  padding: 1rem;
  word-break: break-all;
  white-space: pre-wrap;
  border-radius: .5rem;
  &.Self {
    background-color: colors(chat-self-bg);
    border-radius: .75rem 0 .75rem .75rem;
  }
  &.Partner {
    background-color: colors(chat-partner-bg);
    border-radius: 0 .75rem .75rem;
  }
}
.MessageFooter {
  display: flex;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
  .Datetime {
    margin-left: auto;
  }
}
.Link {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
    text-decoration: underline;
  }
}
.FileLink {
  font-weight: 700;
  text-decoration: underline;
}
.LinkIcon {
  width: 1rem;
  height: 1rem;
  margin-left: .25rem;
}
