@import "src/styles/settings";
.Button {
  display: flex;
  align-items: center;
  border: .0625rem solid transparent;
  border-radius: .25rem;
  transition: background-color 150ms ease-out 0s;
  &.Round {
    border-radius: 3rem;
  }
  &.Large {
    padding: .5rem 1rem;
    font-size: 1rem;
  }
  &.Medium {
    padding: .375rem .75rem;
    font-size: .875rem;
  }
  &.Small {
    padding: .25rem .5rem;
    font-size: .75rem;
  }
  &.WidthAuto {
    width: auto;
  }
  &.WidthFull {
    justify-content: center;
    width: 100%;
  }
  &.BlackPrimary {
    color: colors(white);
    background: linear-gradient(180deg, rgb(255 255 255 / 0%) 2.08%, rgb(255 255 255 / 20%) 100%), colors(gray-900);
    &:disabled {
      background: linear-gradient(180deg, rgb(255 255 255 / 0%) 2.08%, rgb(255 255 255 / 20%) 100%), colors(gray-900);
      opacity: .5;
    }
  }
  &.Primary {
    color: colors(white);
    background-color: colors(primary);
    &:disabled {
      background-color: colors(primary);
      opacity: .5;
    }
  }
  &.Secondary {
    color: colors(primary);
    background-color: colors(white);
    border: .0625rem solid colors(primary);
    &:disabled {
      background-color: colors(white);
      opacity: .5;
    }
  }
  &.Normal {
    color: colors(gray-900);
    background-color: colors(gray-200);
    &:hover, &:active {
      color: colors(gray-900);
      background-color: colors(gray-300);
    }
    &:disabled {
      background-color: colors(gray-200);
      opacity: .5;
    }
  }
  &.Danger {
    color: colors(white);
    background-color: bootstrap-colors(danger-btn);
    &:hover, &:active {
      color: colors(white);
      background-color: bootstrap-colors(danger-btn-hover);
    }
    &:disabled {
      background-color: colors(danger-btn);
      opacity: .5;
    }
  }
  &.Link {
    color: colors(gray-900);
    background-color: colors(white);
    &:hover, &:active {
      color: colors(gray-900);
      background-color: colors(gray-200);
    }
    &:disabled {
      background-color: colors(white);
      opacity: .5;
    }
  }
  &.Outline {
    color: colors(gray-900);
    background-color: colors(white);
    border: .0625rem solid colors(gray-200);
    &:hover, &:active {
      color: colors(gray-900);
      background-color: colors(gray-200);
    }
    &:disabled {
      background-color: colors(white);
      opacity: .5;
    }
  }
}
