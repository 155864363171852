@import "src/styles/settings";
.SimpleSearchContainer [class*="itandi-bb-ui__ComboBox__Menu"] {
  // TableのヘッダーがComboBoxのMenuとz-indexで競合
  // 重なるカラムヘッダーのz-indexはカラムが増えると増加するので余裕を持って20
  z-index: 20;
}
.SearchDetailContainer [class*="itandi-bb-ui__Drawer__Background"] {
  // TableのヘッダーとフッターがDrawerとz-indexで競合
  // 重なるカラムヘッダーのz-indexはカラムが増えると増加するので余裕を持って20
  z-index: 20;
}
