@use "src/styles/settings" as *;
.HtmlContent {
  & > p {
    margin-bottom: 1rem;
    & > a {
      color: colors(link-text);
      &:hover {
        color: colors(link-hover);
      }
    }
  }
}
