@use "src/styles/settings" as *;
.TableRow {
  height: 100%;
  background-color: colors(white);
  border-bottom: .0625rem solid colors(gray-200);
  transition: background-color 150ms ease-out 0s;
  &:hover {
    background-color: colors(gray-50);
  }
  > td {
    padding: 1rem .75rem;
    vertical-align: middle;
  }
}
