@import "src/styles/settings";
.Panel {
  padding: 1.5rem;
  text-align: center;
  cursor: pointer;
  background-color: colors(file-upload-bg);
  border: .125rem dashed colors(border);
  transition: background-color 150ms ease-out 0s;
  &:hover {
    background-color: colors(file-upload-bg-hover);
  }
}
.IconWrapper {
  .Icon {
    width: 2.5rem;
    height: 2.5rem;
    color: colors(file-upload-label);
  }

  margin-bottom: .5rem;
}
.Guide {
  color: colors(file-upload-label);
}
