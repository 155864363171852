@import "src/styles/settings";
.SearchArea {
  position: relative;
  width: 100%;
  .SearchInput {
    padding-left: 2.25rem;
  }
  .SearchIcon {
    position: absolute;
    top: 50%;
    left: .75rem;
    width: 1.25rem;
    height: 1.25rem;
    color: colors(gray-400);
    transform: translateY(-50%);
  }
}
