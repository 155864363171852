@import "src/styles/settings";
.Title {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
}
.CancelReasonLabel {
  color: colors(gray-700);
}
.CancelReason {
  margin-top: .5rem;
  white-space: pre-wrap;
}
