@use "src/styles/settings" as *;
.ChatMessagePane {
  display: flex;
  flex-direction: column;
  width: 50rem;
  background-color: colors(white);
  @include mq("xxl") {
    width: 34rem;
  }
  @include mq("xl") {
    width: 25rem;
  }
  @include mq() {
    display: none;
  }
}
