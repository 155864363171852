@use "src/styles/settings" as *;
.Row {
  display: flex;
  align-items: center;
  padding: .75rem 1rem;
  color: colors(gray-900);
  border-bottom: 1px solid colors(gray-300);
  &:hover {
    color: colors(gray-900);
    text-decoration: none;
    background-color: colors(semantic-link100);
  }
}
.Name {
  font-size: 1rem;
}
.Address {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.ArrowIconWrapper {
  margin-left: auto;
  color: colors(gray-700);
  >svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }
}
