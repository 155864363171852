@import "src/styles/settings";
.ReportTitle {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  @include mq() {
    padding: 1rem 1rem 0;
  }
}
.MainWrapper {
  min-height: calc(100vh - 18.5rem);
  @include mq() {
    min-height: calc(100vh - 15.375rem);
  }
}
.CompleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  padding: .75rem;
  font-size: 1rem;
  font-weight: 700;
  color: colors(white);
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 2.08%, rgb(255 255 255 / 20%) 100%), colors(gray-900);
  border-radius: 3rem;
  @include mq() {
    width: 100%;
    border: .0625rem solid colors(white);
  }
}
.ProgressBar {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 1rem;
  font-size: .75rem;
  background-color: colors(white);
  .message {
    padding: .5rem 0;
  }
}
.DeleteProgressBar {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 1rem;
  font-size: .75rem;
  background-color: colors(semantic-warning);
  .message {
    padding: .5rem 0;
  }
}
