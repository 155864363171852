@use "src/styles/settings" as *;
.FormGroup {
  width: 50rem;
  margin-bottom: 2rem;
}
.GroupLabel {
  padding: 1rem .75rem;
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
  background-color: colors(gray-100);
  border-left: .25rem solid colors(gray-600);
}
.FormList {
  display: flex;
  flex-direction: column;
  border-bottom: .0625rem solid colors(gray-200);
}
.FormBlock {
  display: flex;
  border-top: .0625rem solid colors(gray-200);
}
.FormLabel {
  display: flex;
  align-items: center;
  width: 16rem;
  padding: 1rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
}
.FormContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: .75rem;
  background-color: colors(white);
  .Description {
    margin-top: .125rem;
    font-size: .75rem;
    color: colors(gray-700);
  }
}
.RequireLabel {
  padding: .0625rem .375rem;
  margin-left: auto;
  font-size: .75rem;
  font-weight: 700;
  color: colors(negative);
  background-color: colors(negative-100);
  border: .0625rem solid colors(negative);
  border-radius: .125rem;
}
.OptionalLabel {
  padding: .0625rem .375rem;
  margin-left: auto;
  font-size: .75rem;
  font-weight: 700;
  color: colors(gray-600);
  background-color: colors(gray-200);
  border: .0625rem solid transparent;
  border-radius: .125rem;
}
.ErrorMessage {
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.SubmitButton {
  width: 10rem;
}
