@import "src/styles/settings";
.Switcher {
  &.Disabled {
    opacity: .5;
  }
}
.Input {
  display: none;
  &:checked {
    +label {
      background-color: colors(semantic-positive);
      &::after {
        left: 2.1875rem;
      }
      &:hover {
        background-color: colors(semantic-positive-hover);
      }
    }
  }
}
.Disabled {
  .Slider {
    cursor: not-allowed;
    &::after {
      cursor: not-allowed;
    }
  }
}
.Slider {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 3.5rem;
  height: 1.5rem;
  margin-bottom: 0;
  background-color: colors(gray-400);
  border-radius: 2rem;
  transition: .2s;
  &:hover {
    background-color: colors(gray-500);
  }
  &::after {
    position: absolute;
    top: .25rem;
    left: .25rem;
    z-index: 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    content: "";
    background-color: colors(white);
    border-radius: 100%;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    transition: .2s;
  }
}
