@use "src/styles/settings" as *;
.ProgressIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ProgressIndicatorItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 10rem;
}
.IndicatorItem {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .Icon {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: .25rem;
    line-height: 130%;
    letter-spacing: 0;
    border-radius: 99rem;
  }
}
.Done {
  .Icon {
    color: colors(white);
    background-color: colors(gray-700);
  }
}
.Yet {
  .Icon {
    color: colors(gray-700);
    background-color: colors(gray-200);
  }
}
.Current {
  .Icon {
    color: colors(gray-700);
    background-color: colors(white);
    border: .125rem solid colors(gray-700);
  }
  .Label {
    font-weight: 700;
  }
}
.CheckIcon {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  color: colors(white);
}
.Line {
  position: absolute;
  top: .75rem;
  z-index: 0;
  width: 38%;
  height: .25rem;
  background-color: colors(gray-700);
  &.Left {
    left: 0;
    &.First {
      width: 0;
    }
    &.Yet {
      background-color: colors(gray-200);
    }
  }
  &.Right {
    right: 0;
    &.Last {
      width: 0;
    }
    &.Yet, &.Current {
      background-color: colors(gray-200);
    }
  }
}
.Label {
  text-align: center;
}
