@import "src/styles/settings";
.Table {
  margin-bottom: 2rem;
}
.List {
  width: 100%;
  height: 100%;
  border: .0625rem solid colors(border);
  border-bottom: 0;
  @include mq() {
    border: none;
    border-top: .0625rem solid colors(border);
  }
}
.ListRow {
  height: 100%;
  background-color: colors(white);
  border-bottom: .0625rem solid colors(border);
  &:hover {
    background-color: colors(gray-50);
  }
  .Settlement {
    padding: .75rem .5rem;
    .SumPoint {
      margin-right: 1rem;
      font-size: .875rem;
    }
    .Note {
      margin-top: .25rem;
      font-size: .75rem;
    }
    .SPInformation {
      display: none;
      margin: .25rem 0;
      font-size: .75rem;
      @include mq("xl") {
        display: block;
      }
    }
  }
  .Annotate {
    font-size: .75rem;
    font-weight: 400;
    color: colors(gray-700);
    @include mq("xl") {
      word-wrap: break-word;
      white-space: normal;
    }
  }
  .TaxAmount {
    width: 8rem;
    padding: .75rem .5rem;
    font-size: 1rem;
    font-weight: 700;
    text-align: right;
    white-space: nowrap;
    @include mq("xl") {
      width: 7rem;
      font-size: .875rem;
    }
  }
  .NonTaxAmount {
    width: 8rem;
    padding: .75rem .5rem;
    font-size: 1rem;
    text-align: right;
    white-space: nowrap;
    @include mq("xl") {
      width: 7rem;
      font-size: .875rem;
    }
  }
  .TaxRate {
    width: 8rem;
    padding: .75rem .5rem;
    font-size: .75rem;
    font-weight: 700;
    text-align: right;
    white-space: nowrap;
    @include mq("xl") {
      display: none;
    }
  }
  .Edit {
    width: 3rem;
    padding: .75rem .5rem;
    .EditLink {
      display: block;
      color: colors(link-text);
      text-decoration: underline;
      &:hover {
        color: colors(link-hover);
      }
    }
    .DeleteLink {
      display: block;
      margin-top: .25rem;
      color: colors(red);
      text-decoration: underline;
      &:hover {
        color: colors(link-hover);
      }
      @include mq("xl") {
        margin-top: 1rem;
      }
    }
  }
}
.Header {
  color: colors(gray-700);
  background-color: colors(gray-100);
  > th {
    font-weight: 400;
    vertical-align: middle;
  }
  > th + th {
    border-left: .0625rem solid colors(border);
  }
  .TaxAmount, .NonTaxAmount {
    font-size: .875rem;
    font-weight: 400;
  }
  &:hover {
    background-color: colors(gray-100);
  }
  .OrderHandle {
    background-color: colors(gray-100);
  }
}
.Footer {
  position: sticky;
  bottom: 0;
  background-color: colors(gray-100);
}
.OrderHandle {
  width: 1.25rem;
  height: 100%;
  padding: .25rem;
  text-align: center;
  vertical-align: middle;
  background-color: colors(gray-50);
  > img {
    vertical-align: middle;
  }
}
