@import "src/styles/settings";
.BBHeader {
  position: sticky;
  z-index: 2;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: .875rem;
  background-color: colors(white);
}
.FirstNav {
  position: relative;
  display: flex;
  align-items: center;
  padding: .75rem 1.5rem;
  background: colors(white);
  @include mq() {
    padding: .75rem;
  }
}
.ItandiBBLogoLink {
  width: 10rem;
  min-width: 10rem;
  @include mq() {
    width: 8rem;
    min-width: 8rem;
  }
}
.ItandiBBLogo {
  width: 100%;
}
.AccountBlock {
  margin-left: auto;
}
.DropdownTitle {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  .AvatarIcon {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 1rem;
    @include mq() {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  .Caret {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .25rem;
    color: colors(gray-400);
  }
}
.CompanyName {
  margin-left: .5rem;
  font-weight: 700;
}
.AdminBadge {
  padding: .125rem .25rem;
  font-size: .6875rem;
  font-weight: 700;
  color: colors(white);
  background-color: colors(negative);
  border-radius: .5rem;
}
