@use "src/styles/settings" as *;
.DealMain {
  flex: 2;
}
.Show {
  display: block;
}
.Hide {
  display: none;
}
.Card {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: colors(white);
}
