.container, .container-fluid {
  background-color: #f5f5f5;
  &.global-header-adjuster {
    padding-top: 114px;
  }
  &.fixed {
    overflow: hidden;
  }
}
.container-fluid {
  padding-right: 40px;
  padding-left: 40px;
}
