@use "src/styles/settings" as *;
.TopContainer {
  display: flex;
  margin-bottom: 1rem;
}
.Title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: .04em;
  @include mq() {
    font-size: 1rem;
  }
}
.Count {
  margin-left: .5rem;
  font-size: .75rem;
  font-weight: 400;
  color: colors(gray-700);
}
.ButtonArea {
  display: flex;
  gap: 16px;
  margin-left: auto;
  .Button {
    height: auto;
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.3;
  }
}
.ButtonDropdown {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: .5rem 1rem;
  font: .875rem/1 system-ui;
  line-height: 1.3;
  color: colors(gray-900);
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: colors(white);
  border: 2px solid colors(gray-400);
  border-radius: 99rem;
}
.ButtonDropdownTitle {
  display: flex;
  align-items: center;
}
.Caret {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: .25rem;
}
