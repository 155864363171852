@import "src/styles/settings";
.StatusCard {
  padding: 1.5rem 1.5rem 1rem;
  margin-bottom: 1rem;
  background-color: colors(white);
}
.BackReasonTitle {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
}
.CancelReasonLabel {
  color: colors(gray-700);
}
.BackReason {
  margin: .5rem 0 1rem;
  white-space: pre-wrap;
}
