@use "src/styles/settings" as *;
.FormRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid colors(gray-300);
}
.FormLabelCol {
  display: flex;
  align-items: center;
  width: 15rem;
  padding: 1rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
}
.FormRowLabel {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-200);
}
.BlockIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .5rem;
}
.FormSettingCol {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: .5rem 1rem;
}
.DatePicker {
  max-width: 13rem;
}
.InputSelect {
  min-width: 13rem;
}
.InvalidFeedback {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
.RequiredLabel {
  margin-left: auto;
}
.Description {
  margin-top: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.InputRadioGroup {
  display: flex;
  gap: 1rem;
}
.PropertyTypeLabel {
  display: inline-block;
  padding: .25rem .5rem;
  font-size: .75rem;
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-100);
  border-radius: 99rem;
}
.SelectedPropertyLabel {
  gap: .5rem;
  align-items: center;
}
.SelectedPropertyAddress {
  gap: .5rem;
  align-items: center;
  padding: .25rem .5rem;
  font-size: .75rem;
}
.EditButton {
  padding: .25rem;
  button > div > span {
    font-size: .875rem;
  }
}
