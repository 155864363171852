@import "src/styles/settings";
.NavigationBlock {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 2rem;
  border: .125rem solid colors(border);
  border-radius: .25rem;
}
.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.Icon {
  width: 4rem;
  height: 3rem;
  color: colors(gray-400);
}
.NavigationContent {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.Title {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 700;
  & > .OptionalLabel {
    padding: .125rem .375rem;
    margin-left: .5rem;
    font-size: .75rem;
    color: colors(gray-700);
    background-color: colors(gray-200);
    border-radius: .25rem;
  }
}
.ButtonArea {
  display: grid;
  row-gap: 1rem;
  & > .DetailLinkBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    & > .DetailLinkButton {
      display: block;
      width: 10rem;
      padding: .5rem 1rem;
      font-size: 1rem;
      text-align: center;
    }
    & > .CreateButton {
      width: 10rem;
      padding: .55rem 1rem;
      font-size: 1rem;
      color: colors(gray-900);
      background-color: colors(gray-200);
      border-radius: .25rem;
      &:hover {
        background-color: colors(gray-300);
      }
    }
  }
}
.FileRow {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;
}
.FileLink {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
  }
}
.FileButtons {
  margin-left: .5rem;
}
