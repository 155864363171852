@import "src/styles/settings";
.ModalDialog {
  position: relative;
  max-width: 80%;
  margin: 2rem auto;
  @include mq() {
    max-width: 90%;
    margin: 15rem auto;
  }
}
.Image {
  max-width: 100%;
}
.ModalClose {
  position: absolute;
  top: -.75rem;
  right: -.75rem;
  display: flex;
  align-items: center;
  margin-left: auto;
  .CloseIcon {
    width: 2.5rem;
    height: 2.5rem;
    color: colors(white);
  }
}
.ModalCloseButton {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 700;
  background-color: rgb(94 91 100 / 50%);
  border: none;
  border-radius: 3rem;
  &:focus {
    outline: none;
  }
}
