@use "src/styles/settings" as *;
.SecondNav {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  list-style: none;
  background: colors(theme-bg);
  border-top: .0625rem solid colors(theme);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
.SecondNavMenu {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  list-style: none;
  & > * + * {
    margin-left: 1em;
  }
}
.SecondNavMenuItem {
  position: relative;
  flex: 0 0 auto;
  padding: .8em 0;
  font-weight: 700;
  letter-spacing: .04em;
  a {
    padding: 0 1em;
    color: colors(theme);
    text-decoration: none;
    .second-nav-icons {
      height: 14px;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
      background-color: rgb(0 0 0 / 0%);
    }
    &:hover {
      color: colors(theme);
      text-decoration: none;
    }
  }
  &:hover::after, &[is-active="true"]::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: .25rem;
    content: "";
    background: colors(theme);
    border-radius: 4px 4px 0 0;
  }
  &.right {
    padding-left: 3em;
    margin-left: auto;
  }
  &.Dummy::after {
    white-space: pre-wrap;
    content: "\0020";
  }
}
.Menu {
  display: flex;
  align-items: center;
  line-height: 130%;
}
.MenuIcon {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
}
