@use "src/styles/settings" as *;
.DealLayoutFooter {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.875rem;
  margin: 0 -1.875rem -1.25rem;
  background-color: colors(white);
  box-shadow: 0 .125rem 1rem rgb(0 0 0 / 10%);
  @include mq() {
    padding: .5rem;
    margin: 0;
  }
}
