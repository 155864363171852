@use "sass:math";
@mixin font-size($size, $base: 16) {
  //font-size: $size + px;
  font-size: math.div($size, $base) + rem;
}

$breakpoints: (
  "xxl": "screen and (max-width: 1440px)",
  "xl": "screen and (max-width: 1140px)",
  "lg": "screen and (max-width: 960px)",
  "md": "screen and (max-width: 768px)"
) !default;
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
