@import "src/styles/settings";
.Card {
  position: relative;
  width: 23.5rem;
  padding: 2rem 1.25rem;
  margin: 8rem auto;
  background-color: colors(white);
  border-radius: .5rem;
}
.Title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.LoginButton {
  display: block;
  width: 100%;
  padding: .5rem;
  margin: 1.5rem auto;
  font-size: 1.125rem;
  font-weight: 700;
  color: colors(white);
  text-align: center;
  vertical-align: middle;
  background-color: vendor-colors(theme);
  border: none;
  border-radius: .25rem;
  &:disabled {
    opacity: .65;
  }
}
