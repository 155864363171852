@import "src/styles/settings";
.StatusBar {
  display: flex;
  align-items: center;
  max-width: 42.5rem;
  @include mq() {
    flex-wrap: wrap;
  }
}
.Status {
  flex: 1;
  padding: .5rem;
  margin-bottom: .5rem;
  font-size: .75rem;
  text-align: center;
  white-space: nowrap;
  background-color: rgb(135 100 167 / 10%);
  border-right: 1px solid colors(white);
  &.First {
    border-radius: 100vh 0 0 100vh;
  }
  &.Last {
    border-right: none;
    border-radius: 0 100vh 100vh 0;
  }
  &.Active {
    font-weight: 400;
    color: colors(white);
    background-color: colors(theme);
  }
}
