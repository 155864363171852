@import "src/styles/settings";
.Tag {
  padding: .25rem .875rem;
  font-weight: 700;
  color: colors(gray-700);
  white-space: nowrap;
  background-color: colors(gray-200);
  border-radius: 1rem;
  @include mq() {
    padding: .25rem .375rem;
  }
}
