$breakpoints: (
  "xxl": "screen and (max-width: 1440px)",
  "xl": "screen and (max-width: 1140px)",
  "lg": "screen and (max-width: 960px)",
  "md": "screen and (max-width: 768px)"
) !default;
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$colors: (
  black: #212121,
  red: #a31f24,
  white: #fff,
  semantic: #0080ff,
  semantic-link100: #e8f3ff,
  semantic-linkhover: #4da6ff,
  semantic-positive: #58a942,
  semantic-positive-hover: #44952e,
  semantic-positive-100: #eff7ed,
  semantic-positive-500: #abd4a0,
  semantic-warning: #f0ad4e,
  semantic-warning-100: #fef7ee,
  gray-50: #f9f8fa,
  gray-100: #f4f3f6,
  gray-200: #e4e4e5,
  gray-300: #c9c8cb,
  gray-400: #aeadb1,
  gray-500: #949298,
  gray-600: #79767e,
  gray-700: #5e5b64,
  gray-800: #433f4a,
  gray-900: #282430,
  link-text: #187fc3,
  link-hover: #27a2f2,
  link-bg: rgb(39 162 242 / 10%),
  border: #dfdfdf,
  primary: #187fc3,
  negative: #f44336,
  negative-100: #feedeb,
  file-upload-bg: #f9fbfe,
  file-upload-bg-hover: #f1f5fd, // FIXME
  file-upload-label: #98aab7,
  chat-partner-bg: #dbd7e1,
  chat-self-bg: #ecebf0,
  theme: #8764a7, // 暫定
  theme-bg: rgb(135 100 167 / 10%) // 暫定
);
@function colors($key) {
  @return map-get($colors, $key);
}

$vendorColors: (
  theme: #8764a7
);
@function vendor-colors($key) {
  @return map-get($vendorColors, $key);
}

$bootstrap-colors: (
  info-bg: #d1ecf1,
  info-text: #0c5460,
  info-btn: #17a2bb,
  info-btn-hover: #138496,
  info-border: #bee5eb,
  danger-bg: #f8d7da,
  danger-text: #721c24,
  danger-btn: #dc3545,
  danger-btn-hover: #c82333,
  danger-border: #f5c6cb,
  primary-bg: #cce5ff,
  primary-text: #004085,
  primary-btn: #007bff,
  primary-btn-hover: #0069d9,
  primary-border: #b8daff,
  warning-bg: #fff3cd,
  warning-text: #856404,
  warning-btn: #ffc107,
  warning-btn-hober: #e0a800,
  warning-border: #ffeeba,
  focus-outline: #80bdff
);
@function bootstrap-colors($key) {
  @return map-get($bootstrap-colors, $key);
}

$zIndexes: (
  chat-panel: 6,
  modal-background: 10
);
@function z-index($key) {
  @return map-get($zIndexes, $key);
}
