@use "src/styles/settings" as *;
.Container {
  display: flex;
}
.LeftCol {
  flex: 1;
  min-width: 26rem;
  margin-right: 1.5rem;
  @include mq() {
    display: none;
  }
}
