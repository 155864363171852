@use "src/styles/settings" as *;
.SubVendorCard {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: colors(white);
}
.UnSelectedSubVendorCard {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: colors(semantic-warning-100);
  border: .125rem solid colors(semantic-warning);
  border-radius: .5rem;
}
.SubVendorBlock {
  display: flex;
  align-items: center;
}
.AvatarIcon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.SubVendorName {
  margin-bottom: .25rem;
  font-size: 1rem;
  font-weight: 700;
}
.Description {
  color: colors(gray-700);
}
.AssignSubVendorButton {
  padding: .5rem 1rem;
  margin-left: auto;
  font-size: 1rem;
  color: colors(gray-900);
  text-decoration: none;
  background: colors(gray-200);
  border: none;
  border-radius: 99rem;
  transition: background-color 150ms ease-out 0s;
  &:hover {
    background: colors(gray-300);
  }
  &:active {
    background: colors(gray-400);
  }
}
