@import "src/styles/settings";
.TopArea {
  display: flex;
  padding: 1rem;
  background-color: colors(white);
}
.Uploading {
  width: 40rem;
  @include mq() {
    width: 100%;
  }
}
