@use "src/styles/settings" as *;
.Row {
  display: flex;
  align-items: center;
  padding: .75rem 1rem;
  color: colors(gray-900);
  border-bottom: 1px solid colors(gray-300);
  &:hover {
    color: colors(gray-900);
    text-decoration: none;
    background-color: colors(semantic-link100);
  }
  &.Selected {
    font-weight: 700;
    background-color: colors(semantic-link100);
  }
}
