@use "src/styles/settings" as *;
.BBHeader {
  position: sticky;
  z-index: 1;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background-color: colors(white);
}
.FirstNav {
  position: relative;
  display: flex;
  align-items: center;
  height: 3.75rem;
  padding: 0 1.25rem;
  background: colors(white);
  -ms-overflow-style: none;
}
.ItandiBBLogoLink {
  width: 7.5rem;
  min-width: 7.5rem;
}
.ItandiBBLogo {
  width: 100%;
}
.ModeNameText {
  padding: 0 0 0 1em;
  margin: 0 1em;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.2;
  color: colors(gray-400);
  white-space: nowrap;
  border-left: 1px dotted colors(gray-400);
  @include mq() {
    display: none;
  }
}
.FirstNavMenu {
  display: flex;
  padding: 0;
  margin: .5rem 1rem 0;
  overflow: hidden;
  list-style: none;
}
.FirstNavMenuItem {
  position: relative;
  height: 100%;
  padding: 0 1em;
  margin: 0 .125rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: colors(black);
  text-align: center;
  letter-spacing: .04em;
  white-space: nowrap;
  border-radius: .75rem .75rem 0 0;
  &[is-active="true"] {
    background-color: colors(theme);
    a {
      color: colors(white);
      text-shadow: 0 .0625rem 0 rgb(0 0 0 / 10%);
    }
  }
  a {
    color: colors(black);
    text-decoration: none;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
      background-color: rgb(0 0 0 / 0%);
    }
  }
  &:hover {
    &[product-id="bb"] {
      background: rgb(255 145 65 / 30%);
    }
    &[product-id="bk"] {
      background: rgb(24 127 195 / 30%);
    }
    &[product-id="nk"] {
      background: rgb(0 178 213 / 30%);
    }
    &[product-id="mu"] {
      background: rgb(0 178 213 / 30%);
    }
    &[product-id="kt"] {
      background: rgb(88 169 66 / 30%);
    }
  }
  @media (min-width: 530px) {
    line-height: 3.25rem;
  }
  @media (max-width: 529px) {
    display: none;
  }
}
.NavAccountInformation {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #000;
  white-space: nowrap;
}
.DropdownTitle {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-weight: 700;
  .Caret {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .25rem;
    color: colors(gray-400);
  }
}
