@import "src/styles/settings";
.StatusHandleButtonRow {
  display: flex;
  margin-top: 2rem;
}
.RightButtons {
  display: flex;
  margin-left: auto;
}
.ForwardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem 1.25rem;
  font-size: 1rem;
  color: colors(white);
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 2.08%, rgb(255 255 255 / 20%) 100%), colors(gray-900);
  border-radius: 3rem;
  transition: opacity 150ms ease-out 0s;
  &:hover {
    opacity: .9;
  }
  &:disabled {
    opacity: .5;
  }
}
.BackwardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem 1.25rem;
  margin-right: 1rem;
  font-size: 1rem;
  border-radius: 3rem;
  transition: background-color 150ms ease-out 0s;
  &:disabled {
    opacity: .5;
    &:hover {
      background-color: colors(white);
    }
  }
  &:hover {
    background-color: colors(gray-100);
  }
}
.ConfirmText {
  margin-bottom: .5rem;
}
