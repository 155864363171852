@use "src/styles/settings" as *;
.AddLink {
  display: flex;
  align-items: center;
  color: colors(semantic);
  .AddIcon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: .25rem;
  }
}
.List {
  width: 100%;
  max-width: 50rem;
  height: 100%;
  margin-bottom: 1rem;
  border-bottom: 0;
  @include mq() {
    border: none;
    border-top: .0625rem solid colors(border);
  }
}
.ListRow {
  height: 100%;
  background-color: colors(white);
  border-bottom: .0625rem solid colors(gray-200);
  &:hover {
    background-color: colors(gray-50);
  }
  .Edit {
    width: 4rem;
    padding: 1rem .75rem;
    .EditLink {
      display: block;
      margin-bottom: .25rem;
      color: colors(link-text);
      text-decoration: underline;
      &:hover {
        color: colors(link-hover);
      }
    }
    .DeleteLink {
      display: block;
      color: colors(red);
      text-decoration: underline;
      &:hover {
        color: colors(link-hover);
      }
      @include mq("xl") {
        margin-top: 1.5rem;
      }
    }
  }
  .UnitAmount {
    width: 6rem;
    .UnitAmountLink {
      display: block;
      margin-bottom: .25rem;
      color: colors(link-text);
      text-decoration: underline;
      &:hover {
        color: colors(link-hover);
      }
    }
  }
}
.ListCol {
  padding: 1rem .75rem;
  vertical-align: middle;
}
.Header {
  font-weight: 700;
  color: colors(gray-700);
  background-color: colors(gray-50);
  > th {
    padding: 1rem .75rem;
    vertical-align: middle;
  }
  &:hover {
    background-color: colors(gray-100);
  }
  .OrderHandle {
    padding: .25rem;
    background-color: colors(gray-100);
  }
}
.OrderHandle {
  width: 1.25rem;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: colors(gray-50);
  > img {
    vertical-align: middle;
  }
  .OrderHandleIcon {
    width: 1.5rem;
    height: 1.5rem;
    color: colors(gray-400);
    cursor: pointer;
  }
}
.LinkageTagCol {
  width: 9rem;
}
.LinkageTag {
  padding: .25rem .375rem;
  font-size: .75rem;
  color: colors(white);
  background-color: colors(gray-700);
  border-radius: .25rem;
}
.InviteLinkageTag {
  padding: .25rem .375rem;
  color: colors(link-text);
  text-decoration: underline;
  &:hover {
    color: colors(link-hover);
  }
}
.InputRadioGroup {
  display: flex;
  gap: 1rem;
  margin: .75rem 0 .25rem;
}
.Invite {
  padding: .5rem 0;
  color: colors(gray-700);
  .inviteDescription {
    color: colors(gray-700);
    letter-spacing: .07rem;
  }
}
.Description {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
