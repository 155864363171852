@import "src/styles/settings";
.Title {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
}
.Description {
  margin-bottom: 1rem;
}
.CancelReasonLabel {
  color: colors(gray-700);
}
.CancelReason {
  margin-top: .5rem;
  white-space: pre-wrap;
}
.Buttons {
  display: flex;
}
.DeleteButton {
  padding: .5rem 1rem;
  margin-left: auto;
  color: colors(gray-900);
  text-decoration: none;
  background: colors(white);
  border: 2px solid colors(negative);
  border-radius: 99rem;
  &:disabled {
    opacity: .5;
    &:hover {
      background-color: colors(white);
    }
  }
  &:hover {
    background: colors(gray-100);
  }
  &:active {
    background: colors(gray-200);
  }
}
.StatusHandleButtonRow {
  display: flex;
  margin-top: 2rem;
}
.RightButtons {
  display: flex;
  margin-left: auto;
}
.BackwardButton {
  padding: .75rem 1.25rem;
  margin-right: 1rem;
  border-radius: 3rem;
  transition: background-color 150ms ease-out 0s;
  &:disabled {
    opacity: .5;
    &:hover {
      background-color: colors(white);
    }
  }
  &:hover {
    background-color: colors(gray-100);
  }
}
