@use "src/styles/settings" as *;
.FormContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 360px;
}
.InvalidFeedback {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(negative);
}
