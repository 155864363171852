@use "src/styles/settings" as *;
.InspectionCandidateDates {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: colors(gray-50);
  .CandidateTitle {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    font-weight: 700;
    .Icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: .25rem;
    }
  }
}
.CandidateDateRow {
  margin-bottom: .25rem;
  .Title {
    margin-right: .75rem;
    color: colors(gray-700);
  }
  .Date {
    margin-right: .5rem;
  }
}
