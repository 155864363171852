@use "src/styles/settings" as *;
.ButtonArea {
  margin-top: 1rem;
}
.TodoTemplateCol {
  width: 15rem;
}
.ButtonCol {
  width: 10rem;
}
