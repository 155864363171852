@use "src/styles/settings" as *;
.List {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-block: 1rem;
  margin-top: .5rem;
  border-top: 1px solid colors(border);
  > a {
    width: 49%;
  }
}
