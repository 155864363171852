@import "src/styles/settings";
.ModalDialog {
  margin: 1rem auto;
  &.MediumSize {
    max-width: 600px;
    @include mq() {
      max-width: 95%;
    }
  }
  &.LargeSize {
    max-width: 920px;
    @include mq() {
      max-width: 95%;
    }
  }
}
.ModalContent {
  position: relative;
  background-color: colors(white);
  background-clip: padding-box;
  border: .0625rem solid rgb(0 0 0 / 20%);
  border-radius: 1.25rem;
  outline: 0;
  box-shadow: 0 .25rem 1rem rgb(0 0 0 / 50%);
}
.ModalClose {
  position: absolute;
  top: .675rem;
  right: .675rem;
  display: flex;
  align-items: center;
  margin-left: auto;
  .CloseIcon {
    width: 1.5rem;
    height: 1.5rem;
    color: colors(white);
  }
}
.ModalCloseButton {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 700;
  background-color: rgb(94 91 100 / 50%);
  border: none;
  border-radius: 3rem;
  &:focus {
    outline: none;
  }
}
.ModalHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  @include mq() {
    padding: 1.5rem 1rem 0;
  }
}
.ModalHeaderInner {
  display: flex;
  font-size: 1rem;
  font-weight: 700;
}
.ModalBody {
  position: relative;
  max-height: calc(100vh - 10rem);
  padding: 1.5rem;
  overflow-y: auto;
  white-space: normal;
  @include mq() {
    padding: .5rem;
  }
}
.ModalBodyFooter {
  padding: 1rem 1rem 0;
  margin: 0 -1rem;
  text-align: right;
  border-top: .0625rem solid colors(border);
}
.ModalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-top: .0625rem solid colors(border);
}
.DeleteButton {
  position: absolute;
  left: 1rem;
}
.CloseButton {
  position: absolute;
  top: 1rem;
  right: 1.25rem;
}
