@import "src/styles/settings";
.Tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  background-color: colors(gray-100);
  border-bottom: .125rem solid colors(gray-400);
  > .Tab + .Tab {
    margin-left: .25rem;
  }
}
.Tab {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-weight: 700;
  color: colors(gray-700);
  text-align: center;
  letter-spacing: .1em;
  white-space: nowrap;
  background-color: colors(white);
  border-radius: .5rem .5rem 0 0;
  > .TabLabel {
    padding: .25rem;
    text-decoration: underline;
  }
  &.Active {
    color: colors(white);
    text-decoration: none;
    background-color: colors(gray-600);
    &:hover {
      color: colors(white);
    }
  }
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
