@import "src/styles/settings";
.DropdownList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 15rem;
  padding: .5rem 0;
  margin-top: .25rem;
  background: colors(white);
  border: .0625rem solid colors(border);
  border-radius: .5rem;
  box-shadow: 0 .125rem .25rem rgb(0 0 0 / 10%);
  @include mq() {
    min-width: 10rem;
  }
}
