@use "src/styles/settings" as *;
.DealCard {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: colors(white);
}
.Title {
  display: flex;
  align-items: center;
  padding: .5rem;
  font-size: 1rem;
  font-weight: 700;
  border-bottom: .0625rem solid colors(border);
}
.FileList {
  padding: 1rem;
  @include mq() {
    padding: .5rem 0;
  }
}
.FileRow {
  margin-bottom: .75rem;
}
.FileContent {
  display: flex;
  align-items: center;
}
.FileLink {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
  }
}
.AddButtonWrapper {
  margin-left: auto;
  font-weight: 400;
}
.FileButtons {
  margin-left: .5rem;
}
.NoFile {
  color: colors(gray-400);
}
.Description {
  padding: .5rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.Icon {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
  color: colors(semantic-positive);
}
.Annotation {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
