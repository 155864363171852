@use "src/styles/settings" as *;
.ChatDealListRow {
  display: flex;
  border-top: .0615rem solid colors(gray-200);
}
.ChatDealListRowLink {
  flex: 1;
  align-items: center;
  padding: .5rem 1rem;
  color: colors(gray-900);
  background-color: colors(white);
  transition: background-color 150ms ease-out 0s;
  &.Active {
    background-color: colors(semantic-link100);
    &:hover {
      background-color: colors(semantic-link100);
    }
    .ArrowIcon {
      color: colors(black);
    }
  }
  &:hover {
    color: colors(gray-900);
    text-decoration: none;
    background-color: colors(gray-50);
  }
  &.PC {
    display: flex;
    @include mq() {
      display: none;
    }
  }
  &.SP {
    display: none;
    @include mq() {
      display: flex;
    }
  }
}
.DealContents {
  width: 16rem;
  max-width: 16rem;
  margin-right: .5rem;
  overflow: hidden;
  @include mq("xxl") {
    width: 15rem;
    max-width: 15rem;
  }
  @include mq("md") {
    flex: 1;
    width: 100%;
    max-width: none;
  }
}
.RoomDisplayName {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;
}
.RoomName {
  margin-right: .25rem;
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.RoomNumber {
  font-weight: 700;
  white-space: nowrap;
}
.PartnerName {
  font-size: .75rem;
  color: colors(muted);
}
.LastMessage {
  flex: 1;
  height: 3rem;
  padding: 0 .5rem;
  overflow: hidden;
  font-size: .75rem;
  line-height: 1rem;
  color: colors(muted);
  border-right: .0625rem solid colors(gray-100);
  border-left: .0625rem solid colors(gray-100);
  @include mq("md") {
    display: none;
  }
}
.Tags {
  display: flex;
  flex-direction: row;
  min-width: 3rem;
  margin-left: 1rem;
  line-height: .875rem;
  > span {
    padding: 0 .5rem;
    font-size: .75rem;
  }
  > span + span {
    margin-left: .5rem;
  }
}
.UnreadTag {
  font-weight: 700;
  color: colors(negative);
  background-color: colors(negative-100);
  border: .0625rem solid colors(negative);
  border-radius: .25rem;
}
.ArrowIconWrapper {
  display: flex;
  align-items: center;
  margin: 0 .5rem;
  .ArrowIcon {
    color: colors(gray-400);
  }
}
