@use "src/styles/settings" as *;
.DealCard {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: colors(white);
}
.Title {
  display: flex;
  align-items: center;
  padding: .5rem;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 700;
  border-bottom: .0625rem solid colors(border);
  .TitleIcon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: .5rem;
  }
}
.Content {
  padding: .5rem;
  > .ListContent + .ListContent {
    margin-top: 1rem;
  }
}
.ListContent {
  display: flex;
  flex: 1;
  align-items: flex-start;
  //margin: 1rem 0;
  @include mq("xl") {
    flex-direction: column;
  }
}
.ListContentCaption {
  width: 8.75rem;
  min-width: 8.75rem;
  font-weight: 400;
  color: colors(gray-700);
  @include mq("xl") {
    width: 100%;
    padding: .5rem .75rem;
    background-color: colors(gray-100);
    border-radius: .25rem;
  }
}
.ListContentValue {
  min-width: 12rem;
  margin-bottom: 0;
  margin-left: .75rem;
  > div + div {
    margin-top: .125rem;
  }
  @include mq("xl") {
    margin-top: .25rem;
    margin-left: 0;
  }
}
.NotYet {
  color: colors(gray-400);
}
.Link {
  color: colors(link-text);
  &:hover {
    color: colors(link-hover);
  }
}
.longSentence {
  white-space: pre-wrap;
}
.ButtonArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  margin-top: .5rem;
  border-top: .0625rem solid colors(gray-200);
  @include mq() {
    display: none;
  }
  .Button {
    padding: .5rem 3rem;
    margin: 0 auto;
    font-weight: 700;
    text-align: center;
    border: .125rem solid colors(gray-400);
    border-radius: 3rem;
    box-shadow: 0 .0625rem .125rem rgb(94 91 100 / 5%);
    transition: background-color 150ms ease-out 0s;
    &:hover {
      background-color: colors(gray-100);
    }
    &:active {
      outline: none;
    }
  }
}
.UnreadCountBadge {
  position: relative;
  display: inline-block;
  padding: .125rem .344rem;
  margin-left: .5rem;
  font: bold .75rem/1 sans-serif;
  line-height: .875rem;
  color: colors(white);
  background-color: colors(negative);
  border-radius: 99rem;
}
.InspectedLabel {
  padding: .25rem .5rem;
  margin-left: .5rem;
  font-size: .75rem;
  font-weight: 700;
  color: colors(semantic-positive);
  background-color: colors(semantic-positive-100);
  border-radius: .25rem;
}
.YetInspectLabel {
  padding: .25rem .5rem;
  margin-left: .5rem;
  font-size: .75rem;
  font-weight: 700;
  color: colors(gray-600);
  background-color: colors(gray-100);
  border-radius: .25rem;
}
.EditButtonWrapper {
  margin-left: auto;
  font-weight: 400;
  .EditButton {
    color: colors(link-text);
  }
}
.CalendarIcon {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
  color: #5e5b64;
}
.CalendarDelimiter {
  margin: 0 .25rem;
}
.CalendarContents {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.Date {
  margin-right: .5rem;
}
.UpdateUser {
  font-weight: normal;
}
