@use "src/styles/settings" as *;
.HideFilePostInput {
  position: absolute;
  z-index: -1;
  display: none;
  visibility: hidden;
}
.MessageAttachmentButton {
  margin-right: .5rem;
  margin-left: .5rem;
  background: none;
  border-radius: .25rem;
  > .Icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    background-color: colors(gray-50);
  }
}
