@import "src/styles/settings";
.DatePickerWrapper {
  position: relative;
  display: flex;
}
.Icon {
  position: absolute;
  top: .75rem;
  left: .75rem;
  display: flex;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  color: colors(gray-700);
}
.DatePicker {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 9999 !important;
}
