@use "src/styles/settings" as *;
.TodoTable {
  width: 100%;
}
.TodoTableHeaderRow {
  > th {
    padding: .5rem;
    font-weight: 400;
    color: colors(gray-700);
    vertical-align: middle;
    background-color: colors(gray-100);
    border-bottom: 1px solid colors(gray-200);
  }
  > th + th {
    border-left: 1px solid colors(gray-200);
  }
}
.TodoTableRow {
  border-bottom: 1px solid colors(gray-200);
  > td {
    padding: .5rem;
    vertical-align: middle;
  }
}
.TodoLink {
  color: colors(gray-900);
  text-decoration: underline;
  &:hover {
    color: colors(gray-800);
  }
}
.CheckColumn {
  width: 3rem;
  text-align: center;
}
.DueDateColumn, .CompletedAtColumn {
  width: 12rem;
}
.OperateColumn {
  width: 8rem;
}
.Empty {
  font-size: .75rem;
  color: colors(gray-400);
}
.DeleteLink {
  display: flex;
  align-items: center;
  color: colors(negative);
}
.DropdownMenuIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .75rem;
}
.EmptyPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 7rem;
}
.EmptyMessage {
  margin-bottom: 1rem;
  color: colors(gray-400);
}
.AddButtonArea {
  margin-top: .5rem;
}
.RadioGroup {
  display: flex;
  gap: 1rem;
  padding: .5rem;
}
