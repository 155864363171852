@use "src/styles/settings" as *;
.CompanyRow {
  td {
    padding: .75rem 1.5rem;
    vertical-align: middle;
    border-bottom: .0625rem solid colors(gray-200);
  }
}
.CompanyMasterId {
  font-size: 1rem;
}
.AuthId {
  margin-top: .25rem;
  font-size: .75rem;
  color: colors(gray-700);
}
.ActionButtonCell {
  width: 30rem;
  text-align: right;
  > button + button {
    margin-left: 1rem;
  }
}
.ContractType {
  margin-bottom: .25rem;
}
.ContractTypeLabel {
  padding: .0625rem .375rem;
  font-size: .75rem;
  font-weight: 700;
  color: colors(gray-600);
  background-color: colors(gray-200);
  border: .0625rem solid transparent;
  border-radius: .125rem;
}
.CompanyName {
  font-size: 1rem;
}
.CompanyDetailButton {
  text-decoration: underline;
  &:hover {
    color: colors(gray-700);
  }
}
.CheckIcon {
  color: colors(semantic-positive);
}
.WarningIcon {
  color: colors(semantic-warning);
}
